import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import {ZiggyVue} from '../../vendor/tightenco/ziggy';
import vuetify from './plugins/vuetify';
import {createI18n} from 'vue-i18n'
import profile from './translations/fr/profile.json'
import common from './translations/fr/common.json'
import organization from './translations/fr/organization.json'
import user from './translations/fr/user.json'
import event from './translations/fr/event.json'
import VueApexCharts from "vue3-apexcharts";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {createPinia} from "pinia";
import AppLayoutBis from "@/Layouts/AppLayoutBis.vue";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

if (import.meta.env.MODE !== 'development') {
    Bugsnag.start({
        apiKey: '6f5db96bfa3f1dfcd8e5146c91d98940',
        plugins: [new BugsnagPluginVue()]
    });

    BugsnagPerformance.start({apiKey: '6f5db96bfa3f1dfcd8e5146c91d98940'});
}


const appName = import.meta.env.VITE_APP_NAME || 'Lamikal';

const i18n = createI18n({
    locale: 'fr',
    legacy: false,
    messages: {
        fr: {
            common,
            profile,
            organization,
            user,
            event
        }
    }
});

const pinia = createPinia();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        const module = await resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue', {eager: false})
        );

        let page = module;
        if (!page.default.layout) {
            // Set the default layout for all page components that don't have a layout set
            // Without this check, we will overwrite the page's layout, which we don't want to do
            page.default.layout = AppLayoutBis;
        }
        return page;
    },
    setup({el, App, props, plugin}) {
        let app = createApp({render: () => h(App, props)});
        if (import.meta.env.MODE !== 'development') {
            app.use(Bugsnag.getPlugin('vue'));
        }

        app.use(plugin)
            .use(ZiggyVue)
            .use(vuetify)
            .use(i18n)
            .use(pinia)
            .use(VueApexCharts)
            .component('VueDatePicker', VueDatePicker)
            .mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
        showSpinner: true
    },
});

// Handle inertia history refresh
let stale = false;

window.addEventListener('popstate', () => {
    stale = true;
});

router.on('navigate', (event) => {
    const page = event.detail.page;
    if (stale) {
        router.get(page.url, {}, {replace: true, preserveState: false});
    }
    stale = false;
});
